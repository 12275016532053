import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { COLORS } from "../colors";
import {
  addRevenue,
  auth,
  getAllBookings,
} from "../utils/firebase/firebase.utils";
import { CSVLink } from "react-csv";

const AdminDashboard = () => {
  const currentUser = auth.currentUser;
  const navigate = useNavigate();

  const [bookings, setBookings] = useState([]);
  const [bookingsForExport, setBookingsForExport] = useState([]);
  const [allValues, setAllValues] = useState({});

  const changeHandler = (e) => {
    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  useEffect(() => {
    console.log(currentUser)
    if (!currentUser) {
      navigate("/admin");
    } else if (currentUser.uid !== "tVQXHYIlGURh3w0wQ1jbzzjMDJq1") {
      navigate("/admin");
    }
    const getBookings = async () => {
      const books = await getAllBookings();
      books.sort((a, b) => b.createdAt.toMillis() - a.createdAt.toMillis());
      
      setBookings([...books]);
      const newBooks = JSON.parse(JSON.stringify(books))
      const adaptedBooks = newBooks.map((book) => {

        if (book.additionalGuests === undefined) {
          return { bookingId: book.bookingId }
        }

        const noOfGuests = book.additionalGuests.length
        book["noOfGuests"] = noOfGuests + 1

        const arrDate = new Date(book.arrival);
        const depDate = new Date(book.departure);
        const diffTime = Math.abs(depDate - arrDate);
        const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
        book["nightsStayed"] = diffDays

        const ortst = book.ortstaxe.toString().replace(".", ",");
        book.ortstaxe = ortst

        delete book.additionalGuests
        delete book.signatureUrl
        delete book.identityUrl
        delete book.address
        delete book.birthday
        delete book.firstName
        delete book.lastName
        delete book.gender
        delete book.nationality
        delete book.userId
        delete book.createdAt
        return book
      })
      setBookingsForExport(adaptedBooks)
    };
    getBookings();
  }, []);

  return (
    <div
      style={{
        margin: 20,
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <CSVLink
          style={{
            textDecoration: "none",
            position: "absolute",
            left: 20,
            top: 20,
            background: "none",
            padding: 10,
            fontWeight: "bold",
            borderRadius: 10,
            color: COLORS.tertiary,
            borderColor: COLORS.tertiary,
            borderStyle: "solid",
          }}
          data={bookingsForExport}
          filename={`bookingDataExport.csv`}
          target="_blank"
        >
          Buchungen exportieren
        </CSVLink>
        <h1>Dashboard</h1>
        <button
          style={{
            textDecoration: "none",
            position: "absolute",
            right: 20,
            top: 20,
            background: "none",
            padding: 10,
            fontWeight: "bold",
            borderRadius: 10,
            color: COLORS.darkText,
          }}
          onClick={() => navigate("/admin/create-booking")}
        >
          Neue Buchung anlegen
        </button>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginTop: 50,
          marginBottom: 50,
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {bookings.map((booking) => {
          if (typeof booking.firstName == "undefined") {
            return (
              <div
                key={booking.bookingId}
                style={{
                  width: 500,
                  border: "1px solid black",
                  borderRadius: 20,
                  padding: 20,
                  margin: 50,
                }}
              >
                <h3 style={{ marginBottom: 20 }}>{booking.bookingId}</h3>
                <p> Noch keine Daten vorhanden </p>
                {booking.revenue ? (
                  <div
                    style={{
                      flexDirection: "row",
                      display: "flex",
                      justifyContent: "space-between",
                      marginBlock: 10,
                      width: "90%",
                    }}
                  >
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <p style={{ fontWeight: "bold", marginRight: 5 }}>
                        Einnahmen:
                      </p>
                      <p>{booking.revenue}</p>
                    </div>
                    <div style={{ flexDirection: "row", display: "flex" }}>
                      <p style={{ fontWeight: "bold", marginRight: 5 }}>
                        Ortstaxe (Ust. frei):
                      </p>
                      <p>{booking.ortstaxe}</p>
                    </div>
                  </div>
                ) : (
                  <form
                    onSubmit={async (e) => {
                      e.preventDefault();
                      if (
                        typeof allValues[`${booking.bookingId}`] == "undefined" ||
                        allValues[`${booking.bookingId}`] == ""
                      ) {
                        alert("Not valid");
                        return;
                      }
                      const res = await addRevenue(
                        booking.bookingId,
                        allValues[`${booking.bookingId}`]
                      );
                      res === 200
                        ? alert("Success")
                        : alert("Error uploading revenue");
                    }}
                  >
                    <input
                      name={booking.bookingId}
                      type="number"
                      step="0.01"
                      lang="de"
                      value={allValues[`${booking.bookingId}`]}
                      onChange={changeHandler}
                    />
                    <button type="submit">Update</button>
                  </form>
                )}
              </div>
            );
          }

          return (
            <div
              style={{
                maxWidth: 500,
                border: "1px solid black",
                flexDirection: "column",
                borderRadius: 20,
                padding: 20,
                margin: 50,
                alignItems: "center",
                display: "flex",
              }}
              key={booking.bookingId}
            >
              <h3 style={{ marginBottom: 20 }}>{booking.bookingId}</h3>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "90%",
                  marginBottom: 10,
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>Vorname:</p>
                  <p>{booking.firstName}</p>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>
                    Nachname:
                  </p>
                  <p>{booking.lastName}</p>
                </div>
              </div>
              {/* Second Line */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBlock: 10,
                  width: "90%",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>
                    Geschlecht:
                  </p>
                  <p>{booking.gender}</p>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>
                    Geburtstag:
                  </p>
                  <p>{booking.birthday}</p>
                </div>
              </div>
              {/* Third Line */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBlock: 10,
                  width: "90%",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>
                    Nationalität:
                  </p>
                  <p>{booking.nationality}</p>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>Adresse:</p>
                  <p>{booking.address}</p>
                </div>
              </div>
              {/* Fourth Line */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBlock: 10,
                  width: "90%",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>Stadt:</p>
                  <p>{booking.city}</p>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>PLZ:</p>
                  <p>{booking.plz}</p>
                </div>
              </div>
              {/* Fifth Line */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBlock: 10,
                  width: "90%",
                }}
              >
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>Land:</p>
                  <p>{booking.country}</p>
                </div>
                <div style={{ flexDirection: "row", display: "flex" }}>
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>Region:</p>
                  <p>{booking.state}</p>
                </div>
              </div>
              {/* Sixth Line */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-between",
                  marginBlock: 20,
                  width: "90%",
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: 5,
                      marginBottom: 10,
                    }}
                  >
                    Ankunftsdatum
                  </p>
                  <p>{booking.arrival}</p>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      marginRight: 5,
                      marginBottom: 10,
                    }}
                  >
                    Abreisedatum
                  </p>
                  <p>{booking.departure}</p>
                </div>
              </div>
              {/* Docs */}
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginBlock: 20,
                }}
              >
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    flex: 1,
                    margin: 10,
                  }}
                >
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>Ausweis:</p>
                  <img src={booking.identityUrl} style={{ maxWidth: "100%" }} />
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    display: "flex",
                    flex: 1,
                    margin: 10,
                  }}
                >
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>
                    Unterschrift:
                  </p>
                  <img
                    src={booking.signatureUrl}
                    style={{ maxWidth: "100%" }}
                  />
                </div>
              </div>
              {/* Value Input */}
              {booking.additionalGuests.length > 0 ? (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginBlock: 20,
                    width: "90%",
                  }}
                >
                  <p style={{ fontWeight: "bold", marginRight: 5 }}>
                    Zusätzliche Gäste:
                  </p>
                  {booking.additionalGuests.map((addGuest) => {
                    return (
                      <div>
                        <p>{addGuest.firstName} {addGuest.lastName}</p>
                        <p>{addGuest.birthday}</p>
                      </div>
                    );
                  })}
                </div>
              ) : null}
              {booking.revenue ? (
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                    marginBlock: 10,
                    width: "90%",
                  }}
                >
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <p style={{ fontWeight: "bold", marginRight: 5 }}>
                      Einnahmen:
                    </p>
                    <p>{booking.revenue}</p>
                  </div>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <p style={{ fontWeight: "bold", marginRight: 5 }}>
                      Ortstaxe (Ust. frei):
                    </p>
                    <p>{booking.ortstaxe}</p>
                  </div>
                </div>
              ) : (
                <form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    if (
                      typeof allValues[`${booking.bookingId}`] == "undefined" ||
                      allValues[`${booking.bookingId}`] == ""
                    ) {
                      alert("Not valid");
                      return;
                    }
                    const res = await addRevenue(
                      booking.bookingId,
                      allValues[`${booking.bookingId}`]
                    );
                    res === 200
                      ? alert("Success")
                      : alert("Error uploading revenue");
                  }}
                >
                  <input
                    placeholder="Umsatz eintragen"
                    name={booking.bookingId}
                    value={allValues[`${booking.bookingId}`]}
                    onChange={changeHandler}
                  />
                  <button type="submit">Update</button>
                </form>
              )}
            </div>
          );
        })}
      </div>
      <button
        style={{ background: "none", borderStyle: "solid", padding: 10 }}
        onClick={async () => {
          const books = await getAllBookings();
          setBookings([...books]);
        }}
      >
        Update Bookings
      </button>
    </div>
  );
};

export default AdminDashboard;
