import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { UserContext } from '../contexts/user.context';
import { auth, createNewUser, getApartmentList } from '../utils/firebase/firebase.utils';

const CreateBookingPage = () => {

  const currentUser = auth.currentUser
  const navigate = useNavigate();
  const [bookingNr, setBookingNr] = useState()
  const [revenue, setRevenue] = useState()
  const [password, setPassword] = useState()
  const [apartmentList, setApartmentList] = useState([])
  const [selectedApartment, setSelectedApartment] = useState()

  const handleSubmit = (e) => {
    e.preventDefault()
    createNewUser(`${bookingNr}@yourvienna.homes`, password, bookingNr, selectedApartment, revenue)
  }

  useEffect(() => {
    if (!currentUser) {
      navigate('/admin')
    } else if (currentUser.uid !== "tVQXHYIlGURh3w0wQ1jbzzjMDJq1") {
      navigate('/admin')
    }

    const newList = async () => {
      const aptList = await getApartmentList()
      setApartmentList(aptList)
    }

    newList()
  }, [])

  const setActiveApartment = (e) => {
    setSelectedApartment(e.target.value)
  }

  return (
    <div>
      <h1>Create new Booking</h1>
      <form onSubmit={handleSubmit}>
        <label htmlFor='bookingnr'>Buchungsnummer</label>
        <input
          type="text"
          name="bookingnr"
          onChange={(e) => setBookingNr(e.target.value)}
          required
        >
        </input>
        <label htmlFor='password'>Passwort</label>
        <input
          type="text"
          name="password"
          onChange={(e) => setPassword(e.target.value)}
          minLength={6}
          required
        >
        </input>
        <label htmlFor='revenue'>Umsatz</label>
        <input
          type="number"
          step={0.01}
          name="revenue"
          required
          onChange={(e) => setRevenue(e.target.value)}

        >
        </input>
        <label htmlFor='apartments'>Apartment wählen</label>
        <input list="apartments" name="apartments" required onChange={setActiveApartment} />
        <datalist id="apartments" onSelect={setSelectedApartment}>
          {apartmentList.map((app, index) => {
            return (
              <option key={index} value={app.apartmentId}> {app.name} </option>
            )
          })}
        </datalist>
        <button type='submit'>Erstellen</button>
      </form>
      <button onClick={() => navigate("/admin/dashboard")}>Dashboard</button>
    </div>
  )
}

export default CreateBookingPage